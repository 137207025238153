<template>
    <div class="py-5">
        <div class="mb-4">
            <transition
                enter-active-class="animated fadeIn faster"
                leave-active-class="animated fadeOut faster"
                mode="out-in"
            >
                <component-loader v-if="sessionDataLoading || enumsLoading" :loading="true"></component-loader>
                <div v-else>
                    <router-view
                        @next="onGoFurther"
                        @previous="onGoBack"
                        @reload="onReload"
                        @update="onUpdate"
                        @clear-session="onClearSession"
                        :session-data="sessionData"
                    ></router-view>
                </div>
            </transition>
        </div>
        <div class="container mt-5"  v-if="!sessionDataLoading && !enumsLoading && $route.name !== 'SessionDataSent'">
            <div class="d-flex justify-content-between align-items-center align-content-center mt-5">
                <div>
                    <b-btn
                        v-if="$route.name !== 'Index'"
                        class="d-block d-md-inline-block mb-1"
                        :disabled="!canGoBack"
                        @click.prevent="onGoBack"
                    >
                        <b-icon icon="chevron-double-left"/> {{$t('stepNavigation.previous')}}
                    </b-btn>
                </div>

                <div>
                    <b-btn
                        v-if="$route.name !== 'Signature'"
                        class="d-block d-md-inline-block mb-1"
                        variant="primary"
                        :disabled="!canGoFurther"
                        @click.prevent="onGoFurther"
                    >
                        {{$t('stepNavigation.next')}} <b-icon icon="chevron-double-right"/>
                    </b-btn>
                </div>
            </div>
        </div>
        <acquirer-login-form-modal
            @update="onUpdate"
            :sessionData="sessionData"
        />
    </div>
</template>

<script>
import AcquirerLoginFormModal from '../../Components/AcquirerLoginFormModal.vue'
import ComponentLoader from '../../Components/ComponentLoader.vue'

export default {
    name: 'IndexPage',
    components: {
        ComponentLoader,
        AcquirerLoginFormModal
    },
    data () {
        return {
            sessionDataLoading: false,
            sessionData: null,
            enumsLoading: false,
            showAcquirerForm: false
        }
    },
    computed: {
        canGoBack () {
            switch (this.$route.name) {
                case 'Index':
                    return false
            }
            return true
        },
        canGoFurther () {
            if (!this.sessionData) {
                return false
            }
            switch (this.$route.name) {
                case 'Index':
                    return !!this.sessionData.clientType
                case 'BasicData':
                    return !!this.sessionData.basicData
                case 'CompanyOwners': {
                    const companyOwners = this.sessionData.companyPeople.filter((item) => item.executive)
                    return !!this.sessionData.companyPeople && Array.isArray(companyOwners) && companyOwners.length > 0
                }
                case 'CompanyBrokers': {
                    if (this.sessionData.clientType === 'PO') {
                        return !!this.sessionData.companyPeople && Array.isArray(this.sessionData.companyPeople) && this.sessionData.companyPeople.length > 0
                    }
                    return true
                }
                case 'PersonalId':
                    if (this.sessionData && this.sessionData.ivanHashId) {
                        return !!this.sessionData.personalId
                    }
                    return !!this.sessionData.personalId && Array.isArray(this.sessionData.personalId.uploads) && this.sessionData.personalId.uploads.length > 0
                case 'BankAccounts':
                    return !!this.sessionData.bankAccounts && Array.isArray(this.sessionData.bankAccounts) && this.sessionData.bankAccounts.length > 0 && this.sessionData.bankAccountsConfirmedByAffidavit === true
                case 'LegalInfo':
                    if (this.sessionData && this.sessionData.ivanHashId) {
                        return !!this.sessionData.legalInfo && this.sessionData.legalInfo.vop
                    }
                    return !!this.sessionData.legalInfo
                case 'RealOwners':
                    return Array.isArray(this.sessionData.realOwners) && this.sessionData.realOwners.length > 0
            }
            return false
        },
        nextRoute () {
            if (!this.sessionData) {
                return null
            }
            switch (this.$route.name) {
                case 'Index':
                    return { name: 'BasicData' }
                case 'BasicData':
                    switch (this.sessionData.clientType) {
                        case 'FO':
                        case 'OS':
                            return { name: 'PersonalId' }
                        case 'PO':
                            return { name: 'CompanyOwners' }
                    }
                    break
                case 'PersonalId':
                    return { name: 'CompanyBrokers' }
                case 'CompanyOwners':
                    return { name: 'CompanyBrokers' }
                case 'CompanyBrokers':
                    return { name: 'BankAccounts' }
                case 'BankAccounts':
                    return { name: 'LegalInfo' }
                case 'LegalInfo':
                    return { name: (this.sessionData.clientType === 'PO') ? 'RealOwners' : 'Signature' }
                case 'RealOwners':
                    return { name: 'Signature' }
            }
            return null
        },
        previousRoute () {
            if (!this.sessionData) {
                return null
            }
            switch (this.$route.name) {
                case 'BasicData':
                    return { name: 'Index' }
                case 'PersonalId':
                case 'CompanyOwners':
                    return { name: 'BasicData' }
                case 'CompanyBrokers':
                    return { name: (this.sessionData.clientType === 'PO') ? 'CompanyOwners' : 'PersonalId' }
                case 'BankAccounts':
                    return { name: 'CompanyBrokers' }
                case 'LegalInfo':
                    return { name: 'BankAccounts' }
                case 'RealOwners':
                    return { name: 'LegalInfo' }
                case 'Signature':
                    return { name: (this.sessionData.clientType === 'PO') ? 'RealOwners' : 'LegalInfo' }
            }
            return null
        }
    },
    mounted () {
        this.redirectToRoute()
        this.loadSessionData()
        this.setLocale()
        this.loadEnums()
    },
    methods: {
        redirectToRoute () {
            const query = this.$route.query
            if (!query) {
                return
            }
            switch (query.destination) {
                case 'aml':
                    this.$router.replace({ name: 'AMLLegalInfo' })
                    break
                case 'data-checkup':
                    this.$router.replace({ name: 'DataCheckup' })
                    break
            }
        },
        onClearSession () {
            this.sessionData = null
            this.$store.commit('resetStore')
            this.$store.commit('setExternalAcquirer', null)
            this.setLocale()
        },
        onUpdate (sessionData) {
            this.sessionData = { ...JSON.parse(JSON.stringify(sessionData)) }
            this.$nextTick(() => {
                this.storeExternalAcquirer()
            })
        },
        setLocale () {
            this.$api.locale.update(this.$store.state.lang).catch((error) => {
                console.error(error)
            })
        },
        loadSessionData () {
            this.sessionDataLoading = true
            this.$api.clientSession.read()
                .then((response) => {
                    this.sessionData = { ...response.data }
                }).catch((error) => {
                    if (error.response.status !== 404) {
                        console.error(error)
                    }
                    this.sessionData = null
                }).finally(() => {
                    this.$nextTick(() => {
                        this.sessionDataLoading = false
                        this.storeExternalAcquirer()
                    })
                })
        },
        storeExternalAcquirer () {
            if (this.sessionData && this.sessionData.externalAcquirer) {
                this.$store.commit('setExternalAcquirer', this.sessionData.externalAcquirer)
            }
        },
        loadEnums () {
            const enums = [
                { name: 'countries', id: 'countries' },
                { name: 'personalIdTypes', id: 'personal-id-types' },
                { name: 'genders', id: 'genders' },
                { name: 'currencies', id: 'currencies' },
                { name: 'moneyOrigins', id: 'money-origins' },
                { name: 'riskActivities', id: 'risk-activities' },
                { name: 'realOwnerTypes', id: 'real-owner-types' },
                { name: 'statutoryTypes', id: 'statutory-types' },
                { name: 'realOwnerPurposeTypes', id: 'real-owner-purpose-types' }
            ]
            this.enumsLoading = true
            Promise.all(enums.map(async (item) => {
                const response = await this.$api.enums.read(item.id)
                return {
                    enum: item.name,
                    content: [...response.data]
                }
            })).then((lists) => {
                for (const list of lists) {
                    this.$store.commit('setEnum', list)
                }
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                this.$nextTick(() => {
                    this.enumsLoading = false
                })
            })
        },
        wasFormDataChanged (dataHashName) {
            const state = this.$store.state.dataHashes
            if (!state[dataHashName] || !state[`${dataHashName}Old`]) {
                return false
            }
            return state[dataHashName] !== state[`${dataHashName}Old`]
        },
        confirmUnsavedData () {
            return new Promise((resolve, reject) => {
                let wasChanged = false
                switch (this.$route.name) {
                    case 'BasicData':
                        wasChanged = this.wasFormDataChanged('basicData')
                        break
                    case 'PersonalId':
                        wasChanged = this.wasFormDataChanged('personalId')
                        break
                    case 'CompanyOwners':
                    case 'CompanyBrokers':
                        wasChanged = this.wasFormDataChanged('companyPerson')
                        break
                    case 'BankAccounts':
                        wasChanged = this.wasFormDataChanged('bankAccount')
                        break
                    case 'LegalInfo':
                        wasChanged = this.wasFormDataChanged('legalInfo')
                        break
                    case 'RealOwners':
                        wasChanged = this.wasFormDataChanged('realOwner')
                        break
                }
                if (!wasChanged) {
                    return resolve()
                }
                this.$swal.warning(
                    this.$t('app.unsavedConfirm.title'),
                    this.$t('app.unsavedConfirm.text'),
                    this.$t('app.unsavedConfirm.proceed'),
                    this.$t('app.unsavedConfirm.stay')
                ).then((value) => {
                    if (value === 'ok') {
                        resolve('dataChanged')
                    }
                }).catch(() => {
                    reject(new Error())
                })
            })
        },
        async onGoFurther () {
            try {
                if (!this.canGoFurther || this.nextRoute === null) {
                    return this.$notify.error(this.$t('errors.cannotGoFurther'))
                }
                const response = await this.confirmUnsavedData()
                if (['Index', 'SessionDataSent', 'Signature'].includes(this.$route.name)) {
                    this.$router.push(this.nextRoute)
                    return
                }
                const formData = this.getFormData(this.$route.name)
                let data = formData.data
                if (response === 'dataChanged') {
                    try {
                        data = formData.oldData
                        const response = await this.$api.clientSession.read()
                        this.sessionData = { ...response.data }
                    } catch (error) {
                        console.error(error)
                    } finally {
                        this.$nextTick(() => {
                            this.storeExternalAcquirer()
                        })
                    }
                }

                await this.$serverValidator.validate(formData.name, data)
                this.$router.push(this.nextRoute)
            } catch (error) {
                if (error.message) {
                    console.error(error)
                }
            }
        },
        onGoBack (data) {
            if (!this.canGoBack || this.previousRoute === null) {
                return this.$notify.error(this.$t('errors.cannotGoBack'))
            }
            this.confirmUnsavedData()
                .then(() => {
                    this.$router.push(this.previousRoute)
                }).catch((error) => {
                    if (error.message) {
                        console.error(error)
                    }
                })
        },
        onReload () {
            this.loadSessionData()
        },
        getFormData (routeName) {
            const formData = this.$store.state.formData
            switch (routeName) {
                case 'BasicData':
                    return {
                        name: 'basicData',
                        data: { ...(formData.basicData || {}), clientType: this.sessionData.clientType },
                        oldData: { ...(this.sessionData.basicData || {}), clientType: this.sessionData.clientType }
                    }
                case 'PersonalId':
                    return { name: 'personalId', data: formData.personalId, oldData: this.sessionData.personalId }
                case 'CompanyOwners':
                    return {
                        name: 'companyOwners',
                        data: formData.companyOwners,
                        oldData: [...(this.sessionData.companyPeople || [])].filter(item => item.executive)
                    }
                case 'CompanyBrokers':
                    return {
                        name: 'companyBrokers',
                        data: formData.companyBrokers,
                        oldData: [...(this.sessionData.companyPeople || [])].filter(item => !item.executive)
                    }
                case 'BankAccounts':
                    return { name: 'bankAccounts', data: formData.bankAccounts, oldData: this.sessionData.bankAccounts }
                case 'LegalInfo':
                    return {
                        name: 'legalInfo',
                        data: { ...(formData.legalInfo || {}), clientType: this.sessionData.clientType },
                        oldData: { ...(this.sessionData.legalInfo || {}), clientType: this.sessionData.clientType }
                    }
                case 'RealOwners':
                    return { name: 'realOwners', data: formData.realOwners, oldData: this.sessionData.realOwners }
            }
        }
    }
}
</script>
